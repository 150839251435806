@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');


::-webkit-scrollbar {
    display: none;
}

* {
    font-family: 'DM Serif Display', serif;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

p, h1, h2, h3, h4, h5, img, span {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    margin: 0;
    padding: 0;
}

body {
    background: #191919 !important;
    color: white !important;
    overflow-y: overlay;
}

.header-container {
    position: sticky;
    top: 0px;
}

.desktop-wrapper {
    position: absolute;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 95.3vh;
    margin-top: 20px;

    display: grid;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: hidden;
} 

//Generate a random value for window positioning
@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}

.desktop-wrapper div.window{
    position: absolute;
}

.contact {
    left: #{random_range(50, 750)}px;
    top: #{random_range(50, 300)}px;
}

.about {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.work {
    left: #{random_range(100, 750)}px;
    top: #{random_range(50, 300)}px;
}

.nav-bar-wrapper div{
    margin-right: 20px;
    margin-left: 20px;
    text-align: center;
    background: #191919;
    float: right;

    width: 160px;
    height: auto;
    color: white;
    border: 1px solid white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: 0.2s;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
        cursor: pointer;
        width: 170px;
    }
}

.navbar-active {
    background: white !important;
    color: #191919 !important;
}

.window {
    width: 700px !important;
    height: 400px !important;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: white;
    box-shadow: -5px 5px 15px #191919;
    border: 0.5px solid white;
}

#active-window {
    animation: show-animation 0.1s ease-in 0s forwards;
    display: block;
}

@keyframes show-animation {
    0% {opacity: 0.5;}
    99% {opacity: 1;}
    100% {display: block;}
}

#hidden-window {
    display: none;
}

.window-header {
    display: grid;
    align-items: center;
    background: #191919;
    color: white;
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: move;
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 100%;
    text-align: center;

    button {
        background: transparent;
        border: 1px solid white;
        border-radius: 20px;
        color: white;
        width: 120px;
        transition: all 0.2s;
        position: absolute;
        display: flex;
        margin-left: 10px;

        &:hover {
            width: 135px;
            background: rgba(255, 255, 255, 0.1);
        }

        img {
            width: 18px;
            height: auto;
            margin-top: 3px;
        }
    }

    p {
        margin: 0 auto;
    }
}

.window-content {
    color: #191919;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 80%;
    overflow-y: hidden;

    &.full {
        height: 96vh;
        position: relative;

        > div.work-container {
            height: auto;
            position: relative;
            padding: 0px;
            

            .work-content-container {
                height: auto;
                width: 100%;
                padding-left: 10%;

                .bubble-title {
                    bottom: 20px;
                }

                > img, video {
                    height: auto;
                    width: 80%;
                    margin-bottom: 30px;
                    transition: all 0.2s;

                    &.demo {
                        position: relative;
                        transform: scale(1.1);    
                    }
                }

                .work-text-container {

                    p {
                        margin-top: 20px;
                        width: 70%;
                        font-size: 20px;
                    }
                }
            }
            
        }

        &.dark {
            background: #191919;
            color: rgb(244, 244, 244);
            height: 100vh;
        }
    }

    &.work-minimised {
        height: 87.7%;
        position: relative;
        margin-top: 50px;
        
        > div.work-div {
            height: 99.5%;
            padding: 0px !important;
            position: relative;

            > div.content-wrapper {
                position: absolute;
                bottom: 0px;
                width: 100%;
                height: 85%;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px;
                background: linear-gradient(to top, rgb(255, 255, 255) 0%,rgb(255, 255, 255) 22%,rgb(255, 255, 255) 70%,rgba(255,255,255,0) 100%);

                &.dark {
                    background: linear-gradient(to top, rgb(12,12,12) 0%,rgb(12,12,12) 22%,rgb(12,12,12) 70%,rgba(255,255,255,0) 100%);
                }

                div {
                    margin-bottom: 10px;
                }

                .skills-logo-wrapper img,i {
                    width: 30px;
                    height: auto;
                }
            }


            .line {
                height: 2px;
                width: 100%;
                background: #191919;
                opacity: 0.7;
            }

            .work-description-div {
                width: 60%;
                height: auto;

                &.light {
                    color: white;
                }

                p {
                    font-size: 20px;
                }
            }
        }

        .control-container {
            position: absolute;
            width: 98%;
            height: auto;
            bottom: 8px;
            text-align: center;

                &.light span {
                    color: white;
                }

                img {
                    width: 20px;
                    height: auto;
                    cursor: pointer;
                }

                img:active {
                    transform: scale(1.2);
                }

            }
    }
    
}

.bubble-title {
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 100px;
    border: 1px solid #191919;
    margin-bottom: 20px;
    margin-right: 5px;
    background: white;
    display: inline-block;
    width: auto;
    transition: all 0.2s;

    &.light {
        background: #191919;
        color: white;
        border: 1px solid white;
    }

    &.light:hover {
        background: white;
        color: #191919;
        border: 1px solid white;
    
        a {
            color: #191919 !important;
        }
    }
        

    a {
        text-decoration: none;
        color: #191919;
    }

    p{
        margin-bottom: 0px;
        font-weight: bold;
        margin: 0px;
    }

    &:hover {
        cursor: pointer;
        background: #191919;
        color: white;

        a {
            color: white;
            text-decoration: none;
        }
    }
}

.skills-logo-wrapper {
    width: 80%;
    
    img, svg{
        width: 40px;
        height: auto;
        opacity: 0.5;
        transition: 0.2s;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;

        &:hover {
            opacity: 1;
        }
    }

}

.title-content {
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
}

@keyframes mouse-animation {
    0% {opacity: 0; position: relative; top: -10px;}
    100% {opacity: 1; position: relative; top: -3px;}
}

@keyframes slide-in-animation { 
    0% { position: relative; right: 10px; opacity: 0.0; } 
    100% {position: relative; right: 0px;  opacity: 1;}
}

.slide-in-animation {
    animation: slide-in-animation 0.2s ease-in-out 0s forwards;
}

.mouse-container {
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 10%;

    .mouse {
        border: 3px solid #191919;
        height: 50px;
        width: 30px;
        border-radius: 100px;
        display: grid;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        

        .mouse-dot {
            height: 13px;
            width: 4px;
            background: #191919;
            border-radius: 100px;
            animation: mouse-animation 1s ease-in-out 0s infinite;
        }


    }

}

.journey-container {
    position: relative;
    bottom: 5%;
    height: auto;

    .skills-logo-wrapper img {
        filter: drop-shadow(0 0 4mm white);
        margin-top: 20px;
        margin-right: 40px;
    }
    [aria-django="true"] {
        filter: invert(100%) hue-rotate(142deg) brightness(104%) contrast(104%) !important;
    }

}

.hidden {
    opacity: 0;
    transition: all 1s;
}

.show {
    opacity: 1;
}

.full-window {
    width: 100vw !important;
    background: white;
    padding: 0 !important;
    color: #191919;
}

.introduction-container {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;

    p {
        font-size: 1.5em;
    }
}

.timeline-bar-container {
    position: absolute;
    bottom: -50px;
    width: 100%;

    .timeline-bar {
        background: rgb(244, 244, 244);
        width: 100%;
        height: 3px;
        justify-content: space-around;
        display: flex;
    
        div {
            cursor: pointer;
            transition: 0.2s;
            background: rgb(244, 244, 244);
            border: 3.5px solid #191919;
            height: 25px;
            width: 25px;
            border-radius: 100%;
            position: relative;
            bottom: 10px;
    
            &:hover {
                border: 1px solid rgb(244, 244, 244);
            }
            
            &:active {
                background: #191919;
                border: 3.5px solid rgb(244, 244, 244);
            }
        }
    }
}

.timeline-bar-texts {
    display: flex;
    justify-content: space-around;
    height: 3px;
    margin-top: 10px;
    font-size: clamp(11px, 3vw, 16px);
}

// [Contact Page] Styling 
.contact-content {
    height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;

    > div {
        padding: 10%;
    }


    h1 {
        font-size: 3.5em;
    }

    p{
        font-size: 1.1em;
    }
}

.scroll-text-section {
    display: none;
}

//Responsivness for small tablets and mobile devices [App]
@media only screen and (max-width: 850px) {   

    .window-header {
        button {
            display: none;
        }
    }

    .nav-bar-wrapper div{
        width: 32.5%;
        margin: 0px;
        margin-right: 1%;

        &:hover {
            width: 30%;
        }
    }

    .work-content-container {

        > img, video {
            width: 90% !important;
        }

        .work-text-container {
            
            position: relative;
            height: clamp(16em, 3vw, 10em);
            overflow: scroll;
            width: 90%;

            .scroll-text-section {

                &.hidden {
                    height: 0px;
                }
    
                    &.white {
                        background: linear-gradient(to bottom, 
                                rgba(255,0,0,0), rgba(0, 0, 0, 1));
    
                        span {
                            color: white;
                            display: block;
                        }
                    }
    
                    background: linear-gradient(to bottom, 
                                rgba(255,0,0,0), rgba(25,25,25, 0.8));
                    position: absolute;
                    bottom: 0px;
                    height: 30%;
                    width: 100%;
                    display: grid;
                    align-items: center;
                    justify-content: center;
                    transition: 0.1s;
    
                    span {
                        margin-top: 20px;
                        color: white;
                    }
                }


            p {
                width: 100% !important;
                font-size: 1.1em !important;
            }
        }

        .bubble-title {
            margin-top: 20px;
        }
    }

    .skills-logo-wrapper {
        width: 100%;

        img {
            width: 25px;
            height: auto;
            opacity: 0.8;
        }
    }

    .title-content {
        > h1 {
            font-size: 2.5em !important;
        }
        > p {
            font-size: 1.3em !important;
        }
    }

    .journey-container {
        > h1 {
            font-size: 2.5em !important;
        }
        p {
            font-size: 1em !important;
        }

        .skills-logo-wrapper img,i {
            margin-right: 10px;
        }
    }
}

//Prevent mobile sticky hover effect
@media (hover:none) {
    .nav-bar-wrapper div {
        &:hover {
        background: #191919;
        width: 32.5%;
        }
    }
}